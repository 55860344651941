<template>
  <b-overlay :show="loading" rounded="sm" variant="white">
    <!-- CODE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Codi</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-if="!service" v-model="code" />
        <span v-else>{{ code }}</span>
      </b-col>
    </b-row>

    <!-- DESCRIPTIONS -->
    <b-row v-if="service" class="mb-2">
      <b-col cols="12">
        <h6>Noms i descripcions</h6>
      </b-col>
      <b-col cols="12" v-if="descriptions.length">
        <b-list-group>
          <b-list-group-item
            v-for="serviceDescription in descriptions"
            :key="serviceDescription.uuid"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              :class="{ 'mb-50': serviceDescription.description }"
            >
              <div>
                <b-badge class="mr-50">
                  {{ serviceDescription.language }}
                </b-badge>
                <b>{{ serviceDescription.name }}</b>
              </div>
              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="15"
                @click="deleteServiceDescription(serviceDescription.uuid)"
              />
            </div>
            <small
              v-if="serviceDescription.description"
              v-html="serviceDescription.description"
            >
            </small>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="12" :class="{ 'mt-1': descriptions.length }">
        <b-button size="sm" variant="primary" @click="onAddDescriptionClicked"
          >Afegir</b-button
        >
      </b-col>
    </b-row>

    <!-- TYPE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Tipus de servei</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="type"
          :options="serviceTypeOptions"
          :reduce="(option) => option.value"
        />
      </b-col>
    </b-row>

    <!-- MIN & MAX -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12">
            <h6>Min</h6>
          </b-col>
          <b-col cols="12">
            <b-form-spinbutton v-model="min" min="0" max="100" />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12">
            <h6>Max</h6>
          </b-col>
          <b-col cols="12">
            <b-form-spinbutton v-model="max" min="1" max="100" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>

    <!-- ADD SERVICE DESCRIPTION MODAL -->
    <b-modal
      id="add-service-description-modal"
      title="Afegeix una nova traducció"
      scrollable
      hide-footer
    >
      <service-description-form
        :service="service"
        @cancel="onAddServiceDescriptionCancel"
        @description-added="onServiceDescriptionAdded"
        @add-description-error="onAddServiceDescriptionError"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormSpinbutton,
  BListGroup,
  BListGroupItem,
  BBadge,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { serviceTypeOptions } from "@/utils/select-options";
import ServiceDescriptionForm from "@/views/services/list/components/ServiceDescriptionForm.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BFormSpinbutton,
    BButton,
    vSelect,
    BListGroup,
    BListGroupItem,
    BBadge,
    BModal,
    ServiceDescriptionForm,
  },
  props: {
    service: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      type: null,
      code: null,
      min: null,
      max: null,
    };
  },
  computed: {
    descriptions() {
      return this.service?.descriptions || [];
    },
    serviceTypeOptions() {
      return serviceTypeOptions;
    },
  },
  created() {
    if (this.service) this.initForm();
  },
  methods: {
    initForm() {
      this.type = this.service.type;
      this.code = this.service.code;
      this.min = this.service.min;
      this.max = this.service.max;
    },
    resetForm() {
      this.type = null;
      this.code = null;
      this.min = null;
      this.max = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.service) this.updateService();
      else this.createService();
    },
    createService() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("services/addService", {
            type: this.type,
            code: this.code,
            min: this.min,
            max: this.max,
          })
          .then(() => this.$emit("service-added"))
          .catch(() => {
            this.$emit("add-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    updateService() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("services/updateService", {
            uuid: this.service.uuid,
            type: this.type,
            min: this.min,
            max: this.max,
          })
          .then(() => this.$emit("service-edited"))
          .catch(() => {
            this.$emit("edit-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    deleteServiceDescription(serviceDescriptionUuid) {
      this.loading = true;
      this.$store
        .dispatch("services/deleteServiceDescription", {
          serviceUuid: this.service.uuid,
          serviceDescriptionUuid,
        })
        .finally(() => (this.loading = false));
    },
    onAddDescriptionClicked() {
      this.$bvModal.show("add-service-description-modal");
    },
    onAddServiceDescriptionCancel() {
      this.$bvModal.hide("add-service-description-modal");
    },
    onServiceDescriptionAdded() {
      this.$bvModal.hide("add-service-description-modal");
    },
    onAddServiceDescriptionError() {
      this.$bvModal.hide("add-service-description-modal");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
