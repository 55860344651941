<template>
  <b-card>
    <!-- SERVICES TABLE -->
    <b-static-table
      :items="mappedServices"
      :fields="servicesTableFields"
      :loading="loading"
      :button-text="$t('Nou servei')"
      show-button
      show-search
      show-actions
      @button-click="onNewServiceButtonClicked"
      @item-edit-click="onEditServiceClicked"
      @item-delete-click="onDeleteServiceClicked"
    />

    <!-- ADD SERVICE MODAL -->
    <b-modal
      :id="'add-service-modal'"
      title="Afegeix un nou servei"
      scrollable
      hide-footer
    >
      <service-form
        @cancel="onAddServiceCancel"
        @service-added="onServiceAdded"
        @add-service-error="onAddServiceError"
      />
    </b-modal>

    <!-- EDIT SERVICE MODAL -->
    <b-modal
      :id="'edit-service-modal'"
      title="Edita el servei"
      scrollable
      hide-footer
    >
      <service-form
        :service="selectedService"
        @cancel="onEditServiceCancel"
        @service-edited="onServiceEdited"
        @edit-service-error="onEditServiceError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import ServiceForm from "@/views/services/list/components/ServiceForm.vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import {
  getServiceTypeName,
  getServiceNameFromServiceDescriptions,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { BCard, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    ServiceForm,
    BStaticTable,
  },
  data() {
    return {
      selectedService: null,
      servicesTableFields: [
        { key: "name", label: this.$t("Servei"), sortable: true },
        { key: "type", label: this.$t("Tipus"), sortable: true },
        { key: "code", label: this.$t("Codi"), sortable: true },
        { key: "min", label: this.$t("Min"), sortable: true },
        { key: "max", label: this.$t("Max"), sortable: true },
        {
          key: "actions",
          label: this.$t("Accions"),
          thStyle: { width: "95px" },
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["services/loading"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    mappedServices() {
      return this.services.map((service) => ({
        uuid: service.uuid || this.$t("No definit"),
        name:
          getServiceNameFromServiceDescriptions(
            service.descriptions,
            this.$i18n.locale
          ) || this.$t("No definit"),
        code: service.code || this.$t("No definit"),
        type: getServiceTypeName(service.type) || this.$t("No definit"),
        min: service.min || this.$t("No definit"),
        max: service.max || this.$t("No definit"),
      }));
    },
  },
  created() {
    // Only fetch services if they are not fetched or being fetched
    if (this.services.length === 0 && !this.loading) this.fetchServices();
  },
  methods: {
    fetchServices() {
      this.$store
        .dispatch("services/fetchServices", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchServices.title"),
            this.$t("errors.fetchServices.description")
          )
        );
    },
    onNewServiceButtonClicked() {
      this.$bvModal.show("add-service-modal");
    },
    onEditServiceClicked(serviceUuid) {
      this.selectedService = this.services.find((s) => s.uuid === serviceUuid);
      this.$bvModal.show("edit-service-modal");
    },
    onDeleteServiceClicked(serviceUuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("services/deleteService", serviceUuid)
        .then(() => {
          this.fetchServices();
          notifySuccess(
            "Servei eliminat",
            "El servei ha estat eliminat correctament"
          );
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar el servei"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddServiceCancel() {
      this.$bvModal.hide("add-service-modal");
    },
    onServiceAdded() {
      this.fetchServices();
      this.$bvModal.hide("add-service-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditServiceCancel() {
      this.$bvModal.hide("edit-service-modal");
    },
    onServiceEdited() {
      this.fetchServices();
      this.$bvModal.hide("edit-service-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onEditServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
  },
};
</script>
