<template>
  <div id="service-description-form">
    <!-- CODE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Codi del servei</h6>
      </b-col>
      <b-col cols="12">
        {{ service.code }}
      </b-col>
    </b-row>

    <!-- LANGUAGE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Idioma</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="language"
          :options="languageOptions"
          :reduce="(language) => language.value"
          placeholder="Selecciona un idioma"
          class="w-100"
        />
      </b-col>
    </b-row>

    <!-- NAME -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Nom</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="name" />
      </b-col>
    </b-row>

    <!-- DESCRIPTION -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Descripció (opcional)</h6>
      </b-col>
      <b-col cols="12">
        <quill-editor v-model="description" :options="editorOption" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import { languageOptions } from "@/utils/select-options";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    vSelect,
    quillEditor,
    BButton,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      language: null,
      name: null,
      description: null,
      editorOption: {
        modules: {
          toolbar: [["bold", "italic", "underline"]],
        },
      },
      languageOptions,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("services/addServiceDescription", {
          service: this.service,
          language: this.language,
          name: this.name,
          description: this.description,
        })
        .then(() => this.$emit("description-added"))
        .catch(() => {
          this.$emit("add-description-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#service-description-form {
  .quill-editor {
    .ql-editor {
      p {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
      }
    }
  }
}
</style>
